import * as React from "react";
import "../../App.scss";
import "@/components/Button/button.scss";
import Title from "@/components/Title/Title";
import Col from "@/components/Col/Col";
import Section from "@/components/Section/Section";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const BypassIos = () => (
  <main>
    <CookieCompliance />
    <Header alwaysFixed></Header>

    <Section className="PrivacyPolicy" colFlex>
      <Col size="70">
        <Title
          maintitle="Bypass inaccuracies of traditional tracking."
          subtitle="Get your data"
        ></Title>

        <p className="mb20">
          Since iOS 14 update, getting accurate data became more challenging
          than ever and nobody can afford the price of working with inaccurate
          data. Scaling campaigns that shouldn't be, turning off campaigns that
          should have been bumped, or getting less credit for the results you
          truly delivered.
        </p>
      </Col>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default BypassIos;
